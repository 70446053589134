export enum LogType {
  STATUS = 'status',
  AVAILABILITY = 'availability',
  AVAILABILITY_TYPE = 'availability_type',
  CONTRACT = 'contract',
  NOTE = 'note',
  EMAIL = 'email',
  DISQUALIFICATION_REASONS = 'DisqualificationReasons',
  PROJECT_MATCHING = 'project_matching',
  PROJECT = 'project',
  APPLICATION_STAGE = 'ApplicationStage',
}
export interface Log {
  _id: string;
  authorId: string;
  author: string;
  candidateID: string;
  hubspotProjectId: string;
  oldValue: string;
  newValue: string;
  created_date: string;
  itemType: LogType;
  comment: string;
  note: string;
  metadata: { noteCategory: string };
}

export enum NoteCategory {
  GENERAL = 'General',
  RESCHEDULE_BY_CANDIDATE = 'Reschedule by candidate',
  RESCHEDULE_BY_INTERVIEWER = 'Reschedule by interviewer',
  PING = 'Ping',
}
