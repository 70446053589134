import { CandidateOperator, CandidateOperatorsPayload } from 'types';
import ai from '../axios';
import ENDPOINTS from '../endpoints';

export interface FetchOperatorsPayload {
  includeNextProjectCSManager?: boolean;
}

export const fetchOperators = async ({
  includeNextProjectCSManager = false,
}: FetchOperatorsPayload = {}): Promise<any> =>
  ai.get(`${ENDPOINTS.OPERATORS}?includeNextProjectCSManager=${includeNextProjectCSManager}`);

export const fetchCandidateOperators = async (candidateId: string): Promise<CandidateOperator[]> =>
  ai.get(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/operators`);

export type UpdateOperators = (payload: CandidateOperatorsPayload) => Promise<void>;

export const addCandidateOperator: UpdateOperators = async ({ candidateId, operatorId, isDedicated }) =>
  ai.post(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/operators`, { userId: operatorId, isDedicated });

export const patchCandidateOperator: UpdateOperators = async ({ candidateId, operatorId, isDedicated }) =>
  ai.patch(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/operators/${operatorId}`, { isDedicated });

export const deleteCandidateOperator: UpdateOperators = async ({ candidateId, operatorId }) =>
  ai.delete(`${ENDPOINTS.ONE_CANDIDATE}/${candidateId}/operators/${operatorId}`);
