import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  fetchOperators,
  fetchCandidateOperators,
  addCandidateOperator,
  deleteCandidateOperator,
  patchCandidateOperator,
  type UpdateOperators,
  FetchOperatorsPayload,
} from './operators-service';
import { CandidateOperator, GetPayload, Operator } from 'types';

const CANDIDATE_OPERATORS_QUERY_KEY = 'candidate-operators';

interface User {
  id: string;
  local: {
    email: string;
    name: string;
  };
  canAnonymizeContacts: boolean;
  role?: string;
  nextProjectCSManager?: boolean;
}

export const useOperatorsFetch = (payload: FetchOperatorsPayload = { includeNextProjectCSManager: false }) => {
  return useQuery<Operator[], Error>(
    ['operators', payload],
    () =>
      fetchOperators(payload).then((data) => {
        const users = data as User[];
        if (users && Array.isArray(users)) {
          return users.map(({ role, id, local: { name, email }, canAnonymizeContacts, nextProjectCSManager }) => ({
            id,
            name,
            email,
            canAnonymizeContacts,
            role,
            nextProjectCSManager,
          }));
        }
        return [];
      }),
    {
      staleTime: Infinity,
    }
  );
};

export const useFetchCurrentUser = () => {
  const userId = localStorage.getItem('auth_key');
  const { data, ...rest } = useOperatorsFetch();
  const currentUser = data?.find((operator) => operator.id === userId);
  return { currentUser, ...rest };
};

export const useFetchCandidateOperators = (candidateId: string) => {
  const { data, ...rest } = useQuery<CandidateOperator[], Error>(
    CANDIDATE_OPERATORS_QUERY_KEY,
    () => fetchCandidateOperators(candidateId),
    {
      staleTime: Infinity,
    }
  );
  return {
    candidateOperators: data || [],
    ...rest,
  };
};

const useUpdateCandidateOperators = (func: UpdateOperators) => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, GetPayload<typeof func>>(func, {
    onSuccess: () => {
      queryClient.invalidateQueries(CANDIDATE_OPERATORS_QUERY_KEY);
    },
  });
};

export const useAddCandidateOperator = () => useUpdateCandidateOperators(addCandidateOperator);

export const usePatchCandidateOperator = () => useUpdateCandidateOperators(patchCandidateOperator);

export const useDeleteCandidateOperator = () => useUpdateCandidateOperators(deleteCandidateOperator);
