import { Descendant, Element, Range, Transforms, Editor, type NodeEntry, BaseRange } from 'slate';
import { type ReactEditor } from 'slate-react';

import { CustomText, CustomElement } from 'types';
import { findUrlsInString } from 'utils';

/*
  TODO: Move toggleMark and toggleBlock to higher level
*/
import { toggleMark } from './toolbar/mark-button.utils';
import { toggleBlock } from './toolbar/block-button.utils';
import { parse } from '../html-parser';
import { EMOJI_MAP } from './emoji-map';
export { toggleMark } from './toolbar/mark-button.utils';

/**
 * Checks if a single Editor node is empty
 */
const isEmptyNode = (value: Descendant): boolean => {
  if (!Element.isElement(value)) {
    return !(value as CustomText).text;
  }

  return value.type !== 'bulleted-list' && value.type !== 'numbered-list' && value.children.every(isEmptyNode);
};

/**
 * Checks if an Editor value is empty
 */
export const isEmpty = (value: Array<Descendant>): boolean => value.every(isEmptyNode);

/**
 * NOTE: Not used now.
 *
 * Decorator for editor instance that adds
 * functionality for HTML insert
 */
export const withHtmlInsert = <T extends Editor & ReactEditor>(editor: T): T => {
  const { insertData } = editor;

  editor.insertData = (data) => {
    const html = data.getData('text/html');

    if (html) {
      const fragment = parse(html);
      Transforms.insertFragment(editor, fragment);
      return;
    }

    insertData(data);
  };

  return editor;
};

/**
 * Decorator for editor instance that adds ability
 * to toggle list after you press Enter or Backspace
 * in the beginning of the list item
 */
export const withListsFeatures = <T extends Editor & ReactEditor>(editor: T): T => {
  const { deleteBackward, insertBreak } = editor;

  const getTopLevelNode = (editor: T): CustomElement | null => {
    const { selection } = editor;
    return selection ? (editor.children[selection.anchor.path[0]] as CustomElement) : null;
  };

  const isBeginningOfListItem = (editor: T): boolean => {
    const { selection } = editor;

    if (selection && selection.focus.offset === 0 && selection.anchor.offset === 0 && Range.isCollapsed(selection)) {
      const node = getTopLevelNode(editor) as CustomElement;
      if (node.type === 'bulleted-list' || node.type === 'numbered-list') {
        return true;
      }
    }

    return false;
  };

  editor.deleteBackward = (unit) => {
    if (isBeginningOfListItem(editor)) {
      const node = getTopLevelNode(editor) as CustomElement;
      toggleBlock(editor, node.type);
      return;
    }

    deleteBackward(unit);
  };

  editor.insertBreak = () => {
    const { selection } = editor;

    if (selection && isBeginningOfListItem(editor)) {
      const [currentLeafNode] = Editor.leaf(editor, selection);

      if (!currentLeafNode.text) {
        const node = getTopLevelNode(editor) as CustomElement;
        toggleBlock(editor, node.type);
        return;
      }
    }

    insertBreak();
  };

  return editor;
};

/**
 * Decorator for editor instance
 * adding our custom features
 */
export const withCustomFeatures = <T extends Editor & ReactEditor>(editor: T): T => {
  const { insertBreak } = editor;

  editor.insertBreak = () => {
    const { selection } = editor;

    if (selection) {
      const [currentLeafNode] = Editor.leaf(editor, selection);

      if (currentLeafNode.bold) {
        toggleMark(editor, 'bold');
      }

      if (currentLeafNode.italic) {
        toggleMark(editor, 'italic');
      }
    }

    insertBreak();
  };

  return editor;
};

const findUrlsInText = (text: string) => {
  const matches = findUrlsInString(text);
  return matches ? matches.map((m) => [m.trim(), text.indexOf(m.trim())] as [string, number]) : [];
};

/***
 * Decorator for `<Editable />` for links handling
 */
export const linksDecorator = ([node, path]: NodeEntry): BaseRange[] => {
  const nodeText = (node as CustomText).text;

  if (!nodeText) return [];

  const urls = findUrlsInText(nodeText);

  return urls.map(([url, index]) => ({
    anchor: {
      path,
      offset: index,
    },
    focus: {
      path,
      offset: index + url.length,
    },
    link: true,
  }));
};

/**
 * Decorator for editor instance
 * adding emoji replacement.
 * Example: Hello :fire: -> Hello 🔥
 */
export const withEmojiReplacement = <T extends Editor & ReactEditor>(editor: T): T => {
  const { insertData } = editor;

  const map = (content: string) => content.replace(/:[a-z_]+:/g, (match) => EMOJI_MAP[match] ?? match);

  editor.insertData = (data) => {
    const newData = new DataTransfer();

    const text = data.getData('text/plain');

    if (text) {
      newData.setData('text/plain', map(text));
    }

    for (const type of data.types) {
      if (type !== 'text/plain') {
        newData.setData(type, data.getData(type));
      }
    }

    insertData(newData);
  };

  return editor;
};
