import React, { useReducer, useEffect, ReactNode, useMemo, useState } from 'react';
import { GridRowId } from '@mui/x-data-grid-pro';

import {
  ContactRoles,
  GRID_SETTINGS_LS_KEY,
  COL_SETTINGS_LS_KEY,
  DATA_GRID_COMPACT_FLAG_LS_KEY,
  COLUMNS_INIT_ORDER,
  COLUMNS_ORDER_LS_KEY,
  CONTACT_STATUSES,
  SELECTED_IDS_LS_KEY,
} from 'config';
import {
  GridSettings,
  GridSettingsSetter,
  ColumnStoredSettings,
  ColumnsStoredSettings,
  SortedColumnSetter,
  ColumnSearchQuerySetter,
} from 'types';
import { ConfirmModal } from 'components/index';
import { debounce } from 'utils/debounce';

import { getFiltersCount } from './utils';
import initSettings, {
  memoGridSettingsProps,
  generalFiltersSettingsResetState,
  statusesSettingsResetState,
  advancedFiltersSettingsResetState,
  filtersBySourcesResetState,
  tagsSettingsResetState,
} from './init-settings';
import GridSettingsContext, { DashboardContextState } from './grid-settings-context';

const buildGridSettings = (): GridSettings => {
  const lsGridSettings = window.localStorage.getItem(GRID_SETTINGS_LS_KEY);
  if (!lsGridSettings) {
    window.localStorage.setItem(GRID_SETTINGS_LS_KEY, JSON.stringify(initSettings));
    return initSettings;
  }
  const gridSettings = { ...initSettings, ...JSON.parse(lsGridSettings) } as GridSettings;
  return gridSettings;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getColumnsSettings = (): ColumnsStoredSettings => {
  const lsColumnsSettings = window.localStorage.getItem(COL_SETTINGS_LS_KEY);
  if (!lsColumnsSettings) {
    window.localStorage.setItem(COL_SETTINGS_LS_KEY, JSON.stringify({}));
    return getColumnsSettings();
  }
  return JSON.parse(lsColumnsSettings) as ColumnsStoredSettings;
};

const getCompactViewFlagValue = (): boolean => {
  const lsFlagValue = window.localStorage.getItem(DATA_GRID_COMPACT_FLAG_LS_KEY);
  if (!lsFlagValue) {
    return false;
  }
  return Boolean(lsFlagValue);
};

const getColumnsOrder = (): string[] => {
  const lsOrder = window.localStorage.getItem(COLUMNS_ORDER_LS_KEY);
  if (!lsOrder) {
    return COLUMNS_INIT_ORDER;
  }
  return JSON.parse(lsOrder) as string[];
};

const getSelectedIds = (): GridRowId[] => {
  const lsSelectedIds = window.localStorage.getItem(SELECTED_IDS_LS_KEY);
  if (!lsSelectedIds) {
    return [];
  }
  return JSON.parse(lsSelectedIds) as GridRowId[];
};

const getColumnsStoredSettings = (): Record<string, ColumnStoredSettings> => {
  const lsSettings = window.localStorage.getItem(COL_SETTINGS_LS_KEY);
  if (!lsSettings) {
    return {};
  }
  return JSON.parse(lsSettings) as Record<string, ColumnStoredSettings>;
};

type GridSettingsProviderProps = {
  children: ReactNode;
  shouldConfirmSettingsChange?: boolean;
  gridEntityName?: string;
};

const GridSettingsProvider = ({ children, shouldConfirmSettingsChange, gridEntityName }: GridSettingsProviderProps) => {
  const [
    { gridSettings, isInCompactView, isInColDnDMode, columnsStoredSettings, columnsOrder, selectedIds },
    mutateState,
  ] = useReducer(
    (state: DashboardContextState, payload: Partial<DashboardContextState>): DashboardContextState => {
      return {
        ...state,
        ...payload,
      };
    },
    {
      gridSettings: buildGridSettings(),
      isInCompactView: getCompactViewFlagValue(),
      isInColDnDMode: false,
      columnsStoredSettings: getColumnsStoredSettings(),
      columnsOrder: getColumnsOrder(),
      selectedIds: getSelectedIds(),
    }
  );

  const [isConfirmChangeGridSettingsModalOpened, setIsConfirmChangeGridSettingsModalOpened] = useState<boolean>(false);
  const [temporaryGridSettingsBeforeChangeConfirmation, setTemporaryGridSettingsBeforeChangeConfirmation] =
    useState<GridSettings | null>(null);

  /**
   * sets gridSettings in state and saves the memo props to local storage
   */
  const setChangedGridSettings = (settings: GridSettings, settingChangeRequiresConfirmation = true) => {
    setTemporaryGridSettingsBeforeChangeConfirmation(settings);

    if (settingChangeRequiresConfirmation && shouldConfirmSettingsChange && selectedIds?.length) {
      setTemporaryGridSettingsBeforeChangeConfirmation(settings);
      setIsConfirmChangeGridSettingsModalOpened(true);
    } else {
      handleChangeGridSettings(settings, !settingChangeRequiresConfirmation);
    }
  };

  const handleChangeGridSettings = (settings: GridSettings, shouldKeepSelectedIds = false) => {
    window.localStorage.setItem(
      GRID_SETTINGS_LS_KEY,
      JSON.stringify(memoGridSettingsProps.reduce((acc, prop) => ({ ...acc, [prop]: settings[prop] }), {}))
    );
    mutateState({ gridSettings: settings, selectedIds: shouldKeepSelectedIds ? selectedIds : [] });
    setTemporaryGridSettingsBeforeChangeConfirmation(null);
    setIsConfirmChangeGridSettingsModalOpened(false);
  };

  const handleKeepOldGridSettings = () => {
    mutateState({ gridSettings: buildGridSettings() });

    setTemporaryGridSettingsBeforeChangeConfirmation(null);
    setIsConfirmChangeGridSettingsModalOpened(false);
  };

  /**
   * here we catch <crm2url>?team=<teamName> url
   */
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const team = urlParams.get('team');
    if (team) {
      const newSettings = {
        ...gridSettings,
        role: ContactRoles.TEAM,
        team,
        availabilityType: [],
        availabilityHours: [],
        operatorId: null,
        statuses: CONTACT_STATUSES,
      };
      mutateState({ gridSettings: newSettings });
    }
  });

  const setColumnsOrder = (order: string[]): void => {
    window.localStorage.setItem(COLUMNS_ORDER_LS_KEY, JSON.stringify(order));
    mutateState({ columnsOrder: order });
  };

  const setSelectedIds = (selectedIds: GridRowId[]): void => {
    window.localStorage.setItem(SELECTED_IDS_LS_KEY, JSON.stringify(selectedIds));
    mutateState({ selectedIds: selectedIds });
  };

  const setIsInCompactView = (status: boolean): void => {
    window.localStorage.setItem(DATA_GRID_COMPACT_FLAG_LS_KEY, JSON.stringify(status));
    mutateState({ isInCompactView: status });
  };

  /**
   * sets specific item in grid settings to the <code>value</code>.
   * if any of the find settings is changed, it means the request is a "new" one and therefore
   * the <code>page</code> is defaulted to 1.
   */
  const setGridSettingsItem: GridSettingsSetter = (item, value, settingChangeRequiresConfirmation = true) => {
    const newSettings = { ...gridSettings, [item]: value, ...(item === 'page' ? {} : { page: 1 }) };
    setChangedGridSettings(newSettings, settingChangeRequiresConfirmation);
  };

  const [setGridSettingsItemDebounced] = debounce(setGridSettingsItem, 500);

  const setGridSettings = (setter: (gridSettings: GridSettings) => GridSettings) =>
    setChangedGridSettings(setter({ ...gridSettings }));

  /**
   * sets the role, team, operatorId, availabilityType and availabilityHours filters' values to their 'reset' values.
   */
  const resetGeneralFilters = (): void => {
    const newSettings = {
      ...gridSettings,
      ...generalFiltersSettingsResetState,
    };
    setChangedGridSettings(newSettings);
  };

  const resetStatuses = (): void => {
    const newSettings = {
      ...gridSettings,
      ...statusesSettingsResetState,
    };
    setChangedGridSettings(newSettings);
  };

  const resetTags = (): void => {
    const newSettings = {
      ...gridSettings,
      ...tagsSettingsResetState,
    };
    setChangedGridSettings(newSettings);
  };

  const resetAdvancedFilters = (): void => {
    const newSettings = {
      ...gridSettings,
      ...advancedFiltersSettingsResetState,
    };
    setChangedGridSettings(newSettings);
  };

  const resetFiltersBySources = (): void => {
    const newSettings = {
      ...gridSettings,
      ...filtersBySourcesResetState,
    };
    setChangedGridSettings(newSettings);
  };

  /**
   * since team prop should be displayed only if the appropriated role is selected,
   * the team prop is reset while role is switched to ALL or to FREELANCER
   */
  const setRole = (role: ContactRoles) => {
    const newSettings =
      role !== ContactRoles.TEAM
        ? {
            ...gridSettings,
            role,
            team: null,
          }
        : {
            ...gridSettings,
            role,
          };
    setChangedGridSettings(newSettings);
  };

  const storeColumnVisibility = (colField: string, isVisible: boolean): void => {
    const changedColumnSettings: ColumnStoredSettings = { ...(columnsStoredSettings[colField] || {}), isVisible };
    const newColumnsSettings = { ...columnsStoredSettings, [colField]: changedColumnSettings };

    window.localStorage.setItem(COL_SETTINGS_LS_KEY, JSON.stringify(newColumnsSettings));
    mutateState({ columnsStoredSettings: newColumnsSettings });
  };

  const storeColumnWidth = (colField: string, width: number): void => {
    const changedColumnSettings: ColumnStoredSettings = { ...(columnsStoredSettings[colField] || {}), width };
    const newColumnsSettings = { ...columnsStoredSettings, [colField]: changedColumnSettings };

    window.localStorage.setItem(COL_SETTINGS_LS_KEY, JSON.stringify(newColumnsSettings));
    mutateState({ columnsStoredSettings: newColumnsSettings });
  };

  const setSortedColumn: SortedColumnSetter = (fieldName, sortOption) => {
    setGridSettingsItem('sortedColumn', { fieldName, sortOption }, false);
  };

  /**
   * used for both - setting and resetting field search query;
   * if query is empty (or undefined or null), it is excluded from the columnSearches prop,
   * in the other case it is added (or re-written) to the columnSearch prop.
   */
  const setColumnSearchQuery: ColumnSearchQuerySetter = (fieldName, query) => {
    const newColumnSearches =
      query === '' || query === undefined || query === null
        ? Object.entries(gridSettings.columnSearches).reduce(
            (acc, [key, value]) => ({
              ...acc,
              ...(key !== fieldName ? { [key]: value } : {}),
            }),
            {}
          )
        : { ...gridSettings.columnSearches, [fieldName]: query };
    setGridSettingsItemDebounced('columnSearches', newColumnSearches, false);
  };

  /**
   * removes local storage  data about columns visibility, width and order and
   * set them to the default values
   */
  const resetLocalStorage = () => {
    window.localStorage.removeItem(COL_SETTINGS_LS_KEY);
    window.localStorage.removeItem(COLUMNS_ORDER_LS_KEY);
    mutateState({
      columnsStoredSettings: {},
      columnsOrder: COLUMNS_INIT_ORDER,
    });

    setChangedGridSettings({ ...gridSettings, sortedColumn: {}, columnSearches: {} });
  };

  const setIsInColDnDMode = (status: boolean): void => {
    mutateState({ isInColDnDMode: status });
  };

  const filtersCount = useMemo(() => getFiltersCount(gridSettings), [gridSettings]);

  const resetAll = () => {
    const newSettings = {
      ...gridSettings,
      ...generalFiltersSettingsResetState,
      ...statusesSettingsResetState,
      ...tagsSettingsResetState,
      ...advancedFiltersSettingsResetState,
      ...filtersBySourcesResetState,
      columnSearches: {},
    };
    setChangedGridSettings(newSettings);
  };

  return (
    <GridSettingsContext.Provider
      value={{
        gridSettings,
        columnsStoredSettings,
        isInCompactView,
        isInColDnDMode,
        columnsOrder,
        selectedIds,
        setColumnsOrder,
        setSelectedIds,
        setIsInColDnDMode,
        setIsInCompactView,
        setGridSettingsItem,
        setGridSettingsItemDebounced,
        setGridSettings,
        resetGeneralFilters,
        setRole,
        storeColumnVisibility,
        storeColumnWidth,
        setSortedColumn,
        setColumnSearchQuery,
        resetLocalStorage,
        resetStatuses,
        resetTags,
        resetAdvancedFilters,
        resetFiltersBySources,
        filtersCount,
        resetAll,
      }}
    >
      {children}
      <ConfirmModal
        isOpen={isConfirmChangeGridSettingsModalOpened}
        onClose={() => handleKeepOldGridSettings()}
        onConfirm={() =>
          temporaryGridSettingsBeforeChangeConfirmation &&
          handleChangeGridSettings(temporaryGridSettingsBeforeChangeConfirmation)
        }
        title="Apply a new filter?"
        content={`You have pre-selected ${gridEntityName}s. Applying a new filter will dismiss your previous selection. Sure you want to proceed?`}
        confirmLabel="Proceed with a new filter"
        cancelLabel="Cancel and keep selection"
      />
    </GridSettingsContext.Provider>
  );
};

export default GridSettingsProvider;
