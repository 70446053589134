import { useQuery, useMutation, useQueryClient } from 'react-query';

import { GetPayload } from 'types';

import { prepareMutation, prepareQueryResult } from '../utils';
import {
  fetchHubspotProjects,
  findHubspotProjects,
  FindHubspotProjectsResponse,
  fetchHubspotProject,
  updateHubspotProject,
  fetchHubspotProjectByProjectId,
  sendHubspotProjectToMatchIsReady,
  fetchHubspotProjectCompanies,
  updateProjectAssignee,
} from './hubspot-project-service';

export const useFetchHubspotProjects = () => {
  const result = useQuery('hubspot-projects', fetchHubspotProjects);
  return prepareQueryResult('hubspotProjects', result, []);
};

export const useFindHubspotProjects = ({
  onSuccess,
  ...payload
}: GetPayload<typeof findHubspotProjects> & { onSuccess?: (data: FindHubspotProjectsResponse) => void }) => {
  const result = useQuery(['found-hubspot-projects', JSON.stringify(payload)], () => findHubspotProjects(payload), {
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    staleTime: 10 * 60000, // 10 minutes
  });
  return prepareQueryResult('hubspotProjects', result, null, true);
};

export const useFetchHubspotProject = (payload: GetPayload<typeof fetchHubspotProject>) => {
  const result = useQuery(['hubspot-project', payload.dealId], () => fetchHubspotProject(payload));
  return prepareQueryResult('hubspotProject', result, null, true);
};

export const useUpdateHubspotProject = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateHubspotProject, {
    onSuccess: ({ dealId }) => {
      queryClient.invalidateQueries(['hubspot-project', dealId]);
      queryClient.invalidateQueries(['hubspot-project-logs', dealId]);
    },
  });

  return prepareMutation('updateHubspotProject', mutation);
};

export const useFetchHubspotProjectByProjectId = (payload: GetPayload<typeof fetchHubspotProjectByProjectId>) => {
  const result = useQuery(['hubspot-project-crm', payload.hubspotProjectId], () =>
    fetchHubspotProjectByProjectId(payload)
  );
  return prepareQueryResult('hubspotProject', result, null, true);
};

export const useSendHubspotProjectToMatchIsReady = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(sendHubspotProjectToMatchIsReady, {
    onSuccess: ({ dealId }, { hubspotProjectId }) => {
      queryClient.invalidateQueries(['hubspot-project', dealId]);
      queryClient.invalidateQueries(['hubspot-project-logs', dealId]);
      queryClient.invalidateQueries(['matched-hubspot-project-candidates', hubspotProjectId]);
    },
  });

  return prepareMutation('sendHubspotProjectToMatchIsReady', mutation);
};

export const useFetchHubspotProjectCompanies = () => {
  const result = useQuery('hubspot-project-companies', fetchHubspotProjectCompanies);
  return prepareQueryResult('hubspotProjectCompanies', result, []);
};

export const useUpdateProjectAssignee = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateProjectAssignee, {
    onSuccess: ({ dealId }) => {
      queryClient.invalidateQueries(['hubspot-project', dealId]);
      queryClient.invalidateQueries(['hubspot-project-logs', dealId]);
    },
  });

  return prepareMutation('updateProjectAssignee', mutation);
};
