import { type Descendant, type Element, Text } from 'slate';

const getTextFromTextNode = (node: Descendant): string => {
  if (Text.isText(node)) {
    if (node.bold && node.italic) {
      return node.text && `***${node.text}***`;
    }

    if (node.bold) {
      return node.text && `**${node.text}**`;
    }

    if (node.italic) {
      return node.text && `*${node.text}*`;
    }

    return node.text;
  }

  return '';
};

const trimTrailingWhitespaces = (node: Descendant): Array<Descendant> | null => {
  if (Text.isText(node)) {
    if (node.bold || node.italic) {
      let spacesAtStart = '';
      let spacesAtEnd = '';

      const letters = node.text.split('');

      for (let i = 0; i < letters.length; i++) {
        if (letters[i] !== ' ') {
          break;
        }

        spacesAtStart += ' ';
      }

      for (let i = letters.length - 1; i >= 0; i--) {
        if (letters[i] !== ' ') {
          break;
        }

        spacesAtEnd += ' ';
      }

      return [
        ...(spacesAtStart ? [{ text: spacesAtStart }] : []),
        { ...node, text: node.text.trim() },
        ...(spacesAtEnd ? [{ text: spacesAtEnd }] : []),
      ];
    }
  }

  return null;
};

interface SerializeNodeOptions {
  numberOnList?: number;
}

const serializeNode = (node: Descendant, { numberOnList }: SerializeNodeOptions): string => {
  if (Text.isText(node)) {
    const afterTrim = trimTrailingWhitespaces(node);

    if (afterTrim) {
      return afterTrim.map(getTextFromTextNode).join('');
    }

    return getTextFromTextNode(node);
  }

  const children = node.children
    .map((childNode, i) =>
      serializeNode(childNode, {
        ...(node.type === 'numbered-list' && { numberOnList: i + 1 }),
      })
    )
    .join('');

  const typeMap: Record<Element['type'], string> = {
    'bulleted-list': children,
    'numbered-list': children,
    'list-item': `${numberOnList ? numberOnList + '.' : '-'} ${children}\n`,
    'list-item-text': children,
    paragraph: `${children}\n`,
    link: children,
  };

  return typeMap[node.type] ?? children;
};

const serialize = (nodes: Array<Descendant>): string =>
  nodes
    .map((node) => serializeNode(node, {}))
    .join('')
    .trim();

export default serialize;
