import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

import {
  Candidate,
  GridSettings,
  StatusCounters,
  Log,
  CandidateScreening,
  CandidateProject,
  ColumnsStoredSettings,
  GetPayload,
  OperatorNote,
  Education,
  DynamicCandidateStatistics,
  DuplicatesResponse,
  CandidateFeedback,
} from 'types';
import { dashboardColumnsConfig } from 'config';
import { getColumnVisibility } from 'utils';

import { prepareMutation, prepareQueryResult } from '../utils';
import {
  fetchCandidates,
  getCandidate,
  updateCandidate,
  type CreateCandidatePayload,
  createCandidate,
  type FetchCandidateScreeningsPayload,
  fetchCandidateScreenings,
  type UpdateCandidateScreeningPayload,
  updateCandidateScreening,
  type CreateCandidateScreeningPayload,
  createCandidateScreening,
  type CreateCandidateLogPayload,
  createCandidateLog,
  type FetchCandidateProjectsPayload,
  fetchCandidateProjects,
  type CreateCandidateProjectPayload,
  createCandidateProject,
  type UpdateCandidateProjectPayload,
  updateCandidateProject,
  type DeleteCandidateProjectPayload,
  deleteCandidateProject,
  type GenerateLemonIdPayload,
  generateLemonId,
  type GrantMeLemonAccessPayload,
  grantMeLemonAccess,
  type FetchAppLemonUserAccountPayload,
  fetchAppLemonUserAccount,
  type FetchDuplicatesPayload,
  fetchDuplicates,
  type SendEmailPayload,
  sendEmail,
  fetchOperatorNote,
  updateOperatorNote,
  fetchEducations,
  createEducation,
  updateEducation,
  deleteEducation,
  InviteCandidateToACallPayload,
  inviteCandidateToACall,
  CreateSlackChannelPayload,
  createSlackChannel,
  fetchStatistics,
  softDeleteCandidate,
  unmarkDuplicates,
  unmarkAllDuplicates,
  FetchCandidateFeedbacksPayload,
  fetchCandidateFeedbacks,
  updateCandidateFeedback,
  UpdateCandidateFeedbackPayload,
  createCandidateFeedback,
  CreateCandidateFeedbackPayload,
  fetchCandidateLanguages,
  addCandidateLanguage,
  updateCandidateLanguage,
  deleteCandidateLanguage,
  PatchCandidateNoteLogPayload,
  patchCanidateNoteLog,
} from './candidate-service';

interface CandidatesFetchResponse {
  candidates: Candidate[];
  total: number;
  statusCounters: StatusCounters;
}

export const useFetchCandidates = (gridSettings: GridSettings, columnsSettings: ColumnsStoredSettings) => {
  const visibleColumns = [
    'id',
    ...Object.values(dashboardColumnsConfig).reduce<string[]>((acc, colConfig) => {
      const isColumnVisible = getColumnVisibility(colConfig.field, dashboardColumnsConfig, columnsSettings);
      if (!isColumnVisible) {
        return [...acc];
      }
      if (colConfig.artificial) {
        return [...acc, ...(colConfig?.nativeFields || [])];
      }
      return [...acc, colConfig.field];
    }, []),
  ];
  return useQuery<CandidatesFetchResponse, Error>(['contacts', ...Object.values(gridSettings), ...visibleColumns], () =>
    fetchCandidates(gridSettings, visibleColumns).then((data) => {
      return {
        candidates:
          data.contacts.map((c: Candidate) => {
            const oldestReminderForContact = data.reminders[c.id];

            return {
              ...c,
              oldestReminderTimestamp: oldestReminderForContact?.datetimeMs,
              oldestReminderComment: oldestReminderForContact?.text,
            };
          }) || [],
        total: data.total,
        statusCounters: data.statuses,
      };
    })
  );
};

export const useCandidateGet = (candidateId: string | null) => {
  const { data, isLoading, isError, isFetched, ...rest } = useQuery<Candidate | null, Error>(
    ['candidate', candidateId],
    () => getCandidate(candidateId)
  );
  return {
    candidate: data,
    candidateIsLoading: isLoading,
    candidateFetchError: isError,
    candidateHasBeenFetched: isFetched,
    ...rest,
  };
};

export const useUpdateCandidate = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCandidate, {
    onSuccess: (candidate) => {
      queryClient.invalidateQueries(['candidate-logs', candidate.id]);
      queryClient.invalidateQueries(['candidate-diffs', candidate.id]);
    },
  });

  return prepareMutation('updateCandidate', mutation);
};

export const useCreateCandidate = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useMutation<Candidate, Error, CreateCandidatePayload>(createCandidate, {
    onSuccess: () => {
      enqueueSnackbar('Candidate created', { variant: 'success' });
    },
    onError: (error) => {
      const { message } = error;
      enqueueSnackbar(message || 'Failed to create candidate', { variant: 'error' });
    },
  });
};

export const useFetchCandidateScreenings = (payload: FetchCandidateScreeningsPayload) =>
  useQuery<Array<CandidateScreening>, Error>(['candidate-screening', payload.candidateId], () =>
    fetchCandidateScreenings(payload)
  );
export const useFetchCandidateFeedbacks = (payload: FetchCandidateFeedbacksPayload) =>
  useQuery<Array<CandidateFeedback>, Error>(['candidate-feedback', payload.candidateId], () =>
    fetchCandidateFeedbacks(payload)
  );

export const useCreateCandidateScreening = () => {
  const queryClient = useQueryClient();
  return useMutation<CandidateScreening, Error, CreateCandidateScreeningPayload>(createCandidateScreening, {
    onSuccess: (_, { candidateId }) => {
      queryClient.invalidateQueries(['candidate-screening', candidateId]);
      queryClient.invalidateQueries(['candidate-feedback', candidateId]);
    },
  });
};
export const useUpdateCandidateScreening = () => {
  const queryClient = useQueryClient();
  return useMutation<CandidateScreening, Error, UpdateCandidateScreeningPayload>(updateCandidateScreening, {
    onSuccess: (_, { candidateId }) => {
      queryClient.invalidateQueries(['candidate-screening', candidateId]);
      queryClient.invalidateQueries(['candidate-feedback', candidateId]);
    },
  });
};

export const useCreateCandidateFeedback = () => {
  const queryClient = useQueryClient();
  return useMutation<CandidateFeedback, Error, CreateCandidateFeedbackPayload>(createCandidateFeedback, {
    onSuccess: (_, { candidateId }) => {
      queryClient.invalidateQueries(['candidate-feedback', candidateId]);
      queryClient.invalidateQueries(['candidate-screening', candidateId]);
    },
  });
};
export const useUpdateCandidateFeedback = () => {
  const queryClient = useQueryClient();
  return useMutation<CandidateFeedback, Error, UpdateCandidateFeedbackPayload>(updateCandidateFeedback, {
    onSuccess: (_, { candidateId }) => {
      queryClient.invalidateQueries(['candidate-feedback', candidateId]);
      queryClient.invalidateQueries(['candidate-screening', candidateId]);
    },
  });
};

export const useCreateCandidateLog = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<Log, Error, CreateCandidateLogPayload>(createCandidateLog, {
    onSuccess: (_, { candidateId }) => {
      queryClient.invalidateQueries(['candidate-logs', candidateId]);
      queryClient.invalidateQueries(['candidate', candidateId]);
    },
  });

  return prepareMutation('createCandidateLog', mutation);
};

export const usePatchCandidateNoteLog = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<Log, Error, PatchCandidateNoteLogPayload>(patchCanidateNoteLog, {
    onSuccess: (_, { candidateId }) => {
      queryClient.invalidateQueries(['candidate-logs', candidateId]);
      queryClient.invalidateQueries(['candidate', candidateId]);
    },
  });

  return prepareMutation('patchCanidateNoteLog', mutation);
};

export const useInviteCandidateToACall = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation<boolean, Error, InviteCandidateToACallPayload>(inviteCandidateToACall, {
    onSuccess: (_, { candidateId }) => {
      queryClient.invalidateQueries(['candidate-logs', candidateId]);
    },
  });

  return prepareMutation('inviteCandidateToACall', mutation);
};

export const useFetchCandidateProjects = (payload: FetchCandidateProjectsPayload) => {
  const { data, ...rest } = useQuery<Array<CandidateProject>, Error>(['candidate-projects', payload.candidateId], () =>
    fetchCandidateProjects(payload)
  );
  return { data: data ?? [], ...rest };
};

export const useCreateCandidateProject = () => {
  const queryClient = useQueryClient();
  return useMutation<CandidateProject, Error, CreateCandidateProjectPayload>(createCandidateProject, {
    onSuccess: (data, payload) => {
      queryClient.setQueryData<Array<CandidateProject>>(
        ['candidate-projects', payload.candidateId],
        (candidateProjects) => [...(candidateProjects ?? []), data]
      );
    },
  });
};

export const useUpdateCandidateProject = () => {
  const queryClient = useQueryClient();
  return useMutation<CandidateProject, Error, UpdateCandidateProjectPayload>(updateCandidateProject, {
    onSuccess: (data, payload) => {
      // TODO: Avoid extra request. It requires API fix

      // queryClient.setQueryData<Array<CandidateProject>>(
      //   ['candidate-projects', payload.candidateId],
      //   (candidateProjects) => (candidateProjects ?? []).map((project) => (project.id === data.id ? data : project))
      // );
      queryClient.invalidateQueries(['candidate-projects', payload.candidateId]);
    },
  });
};

export const useDeleteCandidateProject = () => {
  const queryClient = useQueryClient();
  return useMutation<CandidateProject, Error, DeleteCandidateProjectPayload>(deleteCandidateProject, {
    onSuccess: (_, payload) => {
      queryClient.setQueriesData<Array<CandidateProject>>(
        ['candidate-projects', payload.candidateId],
        (candidateProjects) => (candidateProjects ?? []).filter((project) => project.id !== payload.projectId)
      );
    },
  });
};

export const useGenerateLemonId = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<{ token: string; userId: string; candidate: Candidate }, Error, GenerateLemonIdPayload>(
    generateLemonId,
    {
      onSuccess: ({ candidate }) => {
        queryClient.setQueriesData<Candidate | undefined>(['candidate', candidate.id], () => candidate);
        enqueueSnackbar('Lemon ID generated', { variant: 'success' });
      },
      onError: (error) => {
        const { message } = error;
        enqueueSnackbar(message || 'Failed to generate Lemon ID', { variant: 'error' });
      },
    }
  );
};

export const useGrantMeLemonAccess = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<void, Error, GrantMeLemonAccessPayload>(grantMeLemonAccess, {
    onSuccess: (_, { candidateId }) => {
      queryClient.invalidateQueries(['app-lemon-user', candidateId]);
      enqueueSnackbar('Me.Lemon access granted', { variant: 'success' });
    },
    onError: (error) => {
      const { message } = error;
      enqueueSnackbar(message || 'Failed to grant Me.Lemon access', { variant: 'error' });
    },
  });
};

export const useFetchAppLemonUserAccount = (payload: FetchAppLemonUserAccountPayload) =>
  useQuery<{} | null, Error>(['app-lemon-user', payload.candidateId], () => fetchAppLemonUserAccount(payload));

export const useFetchDuplicates = (payload: FetchDuplicatesPayload) => {
  const { data: data, ...rest } = useQuery<DuplicatesResponse, Error>(['duplicates', payload.candidateId], () =>
    fetchDuplicates(payload)
  );
  return { data: data ?? { duplicates: [], unmarkedDuplicates: [] }, ...rest };
};

export const useUnmarkDuplicates = (isUnmark: boolean) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation(unmarkDuplicates, {
    onSuccess: ({ contact1, contact2 }) => {
      queryClient.invalidateQueries(['duplicates', contact1.id]);
      queryClient.invalidateQueries(['duplicates', contact2.id]);
      enqueueSnackbar(`Candidate ${isUnmark ? 'unmarked' : 'marked back'} as duplicate`, { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar(`Failed to ${isUnmark ? 'unmark candidate' : 'mark candidate back'} as duplicate`, {
        variant: 'error',
      });
    },
  });

  return prepareMutation('unmarkDuplicate', mutation);
};

export const useUnmarkAllDuplicates = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation(unmarkAllDuplicates, {
    onSuccess: ({ contact1, otherContacts }) => {
      queryClient.invalidateQueries(['duplicates', contact1.id]);
      otherContacts.forEach((contact) => {
        queryClient.invalidateQueries(['duplicates', contact.id]);
      });
      enqueueSnackbar(`All candidates are unmarked as duplicates`, { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar(`Failed to unmark all candidates as duplicates`, {
        variant: 'error',
      });
    },
  });

  return prepareMutation('unmarkAllDuplicates', mutation);
};

export const useSendEmail = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, SendEmailPayload>(sendEmail, {
    onSuccess: (_, { candidateId }) => {
      queryClient.invalidateQueries(['candidate-logs', candidateId]);
    },
  });
};

export const useFetchOperatorNote = (payload: GetPayload<typeof fetchOperatorNote>) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleError = (error: Error) => {
    const { message } = error;
    enqueueSnackbar(message || 'Failed to fetch the operator note', { variant: 'error' });
  };

  const { data, isLoading, isError } = useQuery<OperatorNote, Error>(
    ['operator-note', payload.candidateId],
    () => fetchOperatorNote(payload),
    { onError: handleError }
  );

  return {
    operatorNote: data || null,
    operatorNoteIsLoading: isLoading,
    operatorNoteFetchError: isError,
  };
};

export const useUpdateOperatorNote = () => {
  const queryClient = useQueryClient();
  return useMutation<OperatorNote, Error, GetPayload<typeof updateOperatorNote>>(updateOperatorNote, {
    onSuccess: (operatorNote, payload) => {
      queryClient.setQueriesData<OperatorNote>(['operator-note', payload.candidateId], () => operatorNote);
    },
  });
};

export const useFetchEducations = (payload: GetPayload<typeof fetchEducations>) => {
  const result = useQuery<Array<Education>, Error>(['educations', payload.candidateId], () => fetchEducations(payload));
  return prepareQueryResult('educations', result, []);
};

export const useCreateEducation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<Education, Error, GetPayload<typeof createEducation>>(createEducation, {
    onSuccess: (data, payload) => {
      queryClient.setQueriesData<Array<Education>>(['educations', payload.candidateId], (educations) => [
        ...(educations ?? []),
        data,
      ]);
    },
  });

  return prepareMutation('createEducation', mutation);
};

export const useUpdateEducation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<Education, Error, GetPayload<typeof updateEducation>>(updateEducation, {
    onSuccess: (data, payload) => {
      queryClient.setQueriesData<Array<Education>>(['educations', payload.candidateId], (educations) =>
        (educations ?? []).map((education) => (education.id === data.id ? data : education))
      );
    },
  });

  return prepareMutation('updateEducation', mutation);
};

export const useDeleteEducation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<void, Error, GetPayload<typeof deleteEducation>>(deleteEducation, {
    onSuccess: (_, payload) => {
      queryClient.setQueriesData<Array<Education>>(['educations', payload.candidateId], (educations) =>
        (educations ?? []).filter((education) => education.id !== payload.educationId)
      );
    },
  });

  return prepareMutation('deleteEducation', mutation);
};

export const useCreateSlackChannel = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation<void, Error, CreateSlackChannelPayload>(createSlackChannel, {
    onSuccess: (_, { candidateId }) => {
      queryClient.invalidateQueries(['candidate', candidateId]);
      enqueueSnackbar('Slack channel created', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Failed to create Slack channel', { variant: 'error' });
    },
  });
};

export const useFetchDynamicCandidateStatistics = (payload: GetPayload<typeof fetchStatistics>) => {
  const result = useQuery<DynamicCandidateStatistics, Error>(
    ['dynamicCandidateStatistics', payload.candidateId, payload.startDate],
    () => fetchStatistics(payload)
  );
  return prepareQueryResult('dynamicCandidateStatistics', result, null, true);
};

export const useSoftDeleteCandidate = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(softDeleteCandidate, {
    onSuccess: (candidate) => {
      queryClient.invalidateQueries(['candidate', candidate.id]);
      queryClient.invalidateQueries(['candidate-logs', candidate.id]);
      queryClient.invalidateQueries(['candidate-diffs', candidate.id]);
    },
  });

  return prepareMutation('softDeleteCandidate', mutation);
};

export const useFetchCandidateLanguages = (payload: GetPayload<typeof fetchCandidateLanguages>) => {
  const result = useQuery(['candidate-languages', payload.candidateId], () => fetchCandidateLanguages(payload));
  return prepareQueryResult('candidateLanguages', result, []);
};

export const useAddCandidateLanguage = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(addCandidateLanguage, {
    onSuccess: (_, { candidateId }) => {
      queryClient.invalidateQueries(['candidate-languages', candidateId]);
      queryClient.invalidateQueries(['candidate-logs', candidateId]);
      queryClient.invalidateQueries(['candidate-diffs', candidateId]);
    },
  });

  return prepareMutation('addCandidateLanguage', mutation);
};

export const useUpdateCandidateLanguage = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCandidateLanguage, {
    onSuccess: (_, { candidateId }) => {
      queryClient.invalidateQueries(['candidate-languages', candidateId]);
      queryClient.invalidateQueries(['candidate-logs', candidateId]);
      queryClient.invalidateQueries(['candidate-diffs', candidateId]);
    },
  });

  return prepareMutation('updateCandidateLanguage', mutation);
};

export const useDeleteCandidateLanguage = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteCandidateLanguage, {
    onSuccess: (_, { candidateId }) => {
      queryClient.invalidateQueries(['candidate-languages', candidateId]);
      queryClient.invalidateQueries(['candidate-logs', candidateId]);
      queryClient.invalidateQueries(['candidate-diffs', candidateId]);
    },
  });

  return prepareMutation('deleteCandidateLanguage', mutation);
};
