import React, { memo, useState, useCallback, useEffect, useRef } from 'react';
import { Descendant, Transforms } from 'slate';
import { Slate, RenderElementProps, RenderLeafProps } from 'slate-react';
import isHotkey from 'is-hotkey';
import clsx from 'clsx';
import equal from 'deep-equal';
import { Box, SxProps } from '@mui/material';

import { EditorTemplate } from 'types';
import { useAltHeldDown } from 'hooks';

import Toolbar from './toolbar';
import Element from './element';
import Leaf from './leaf';
import { INITIAL_VALUE, HOTKEYS } from './base-editor.constants';
import { isEmpty, toggleMark, linksDecorator } from './base-editor.utils';
import { EditorField, StyledEditable, StyledFormInput } from './base-editor.styled';
import { useEditor } from './base-editor.hooks';

export interface Props {
  className?: string;
  sx?: SxProps;
  label?: string;
  initialValue?: Array<Descendant>;
  onChange?: (value: Array<Descendant>) => void;
  onSubmit?: (value: Array<Descendant>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  error?: boolean | string;
  readOnly?: boolean;
  heightRestriction?: boolean;
  templates?: Array<EditorTemplate>;
  withoutToolbar?: boolean;
  dataTestId?: string;
  autoFocus?: boolean;
}

const BaseEditor: React.FC<Props> = ({
  className,
  sx,
  label = 'Editor',
  initialValue = INITIAL_VALUE,
  onChange = () => {},
  onSubmit = () => {},
  onFocus = () => {},
  onBlur = () => {},
  error,
  readOnly = false,
  heightRestriction = false,
  templates,
  withoutToolbar = false,
  dataTestId,
  autoFocus,
}) => {
  const editor = useEditor();

  const editorFieldRef = useRef<HTMLDivElement>();

  const valueBeforeEditing = useRef<Array<Descendant> | null>(null);

  /**
   * Helps to avoid double submit
   * after adding a template.
   */
  const submittingAllowed = useRef<boolean>(true);

  const [localValue, setLocalValue] = useState<Array<Descendant>>(initialValue);

  /**
   * Should fix issues with rerendering of StyledEditable
   */
  const [version, setVersion] = useState<number>(0);

  const [focused, setFocused] = useState<boolean>(false);

  const [hovered, setHovered] = useState<boolean>(false);

  const { altHeldDown, resetAltWatcher } = useAltHeldDown();

  const renderElement = useCallback((props: RenderElementProps) => <Element {...props} />, []);

  const renderLeaf = useCallback(
    (props: RenderLeafProps) => (
      <Leaf {...props} altHeldDown={altHeldDown} resetAltWatcher={resetAltWatcher} readOnly={readOnly} />
    ),
    [altHeldDown, resetAltWatcher, readOnly]
  );

  const handleChange = useCallback(
    (value: Array<Descendant>) => {
      setLocalValue(value);
      onChange(value);
    },
    [onChange]
  );

  const handleKeyDown = useCallback<React.KeyboardEventHandler<HTMLDivElement>>(
    (event) => {
      Object.keys(HOTKEYS).forEach((hotkey) => {
        if (isHotkey(hotkey, event)) {
          event.preventDefault();
          const mark = HOTKEYS[hotkey];
          toggleMark(editor, mark);
        }
      });
    },
    [editor]
  );

  const handleFocus = useCallback<React.FocusEventHandler<HTMLDivElement>>(() => {
    setFocused(true);
    onFocus();
    valueBeforeEditing.current = localValue;
    submittingAllowed.current = true;
  }, [localValue, onFocus]);

  const handleBlur = useCallback<React.FocusEventHandler<HTMLDivElement>>(() => {
    /*
      setTimeout is used for delay
      in case a template is added
    */
    setTimeout(() => {
      setFocused(false);
      onBlur();

      if (submittingAllowed.current && !equal(localValue, valueBeforeEditing.current)) {
        onSubmit(localValue as Array<Descendant>);
      }

      valueBeforeEditing.current = null;
    }, 50);
  }, [onSubmit, localValue, onBlur]);

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const rerenderEditor = useCallback(() => {
    setVersion((version) => version + 1);
  }, []);

  const toolbarSubmitHandler = useCallback(
    (value: Array<Descendant>) => {
      onSubmit(value);
      submittingAllowed.current = false;
    },
    [onSubmit]
  );

  useEffect(() => {
    setLocalValue(initialValue);

    /*
      Workaround for issue related
      to crash in case selection
      is in the place being removed
    */
    Transforms.deselect(editor);

    editor.children = initialValue;
    rerenderEditor();
  }, [editor, initialValue, rerenderEditor]);

  useEffect(() => {
    if (autoFocus) {
      const editable = editorFieldRef.current!.querySelector('div[role="textbox"]') as HTMLDivElement;
      editable.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    if (focused) {
      setTimeout(() => {
        const editable = editorFieldRef.current!.querySelector('div[role="textbox"]') as HTMLDivElement;
        editable.focus();
      }, 50);
    }
  }, [focused]);

  return (
    <Box className={clsx(className)} sx={sx}>
      {/* "value" prop is just the initial value of editor. It's an uncontrolled component
        https://github.com/ianstormtaylor/slate/pull/4540*/}
      <Slate editor={editor} value={initialValue} onChange={handleChange}>
        {!readOnly && !withoutToolbar ? (
          <Toolbar
            templates={templates}
            rerenderEditor={rerenderEditor}
            onChange={onChange}
            onSubmit={toolbarSubmitHandler}
          />
        ) : null}
        <EditorField ref={editorFieldRef}>
          <StyledEditable
            key={version}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            spellCheck
            readOnly={readOnly}
            heightRestriction={heightRestriction}
            decorate={linksDecorator}
          />
          {!readOnly ? (
            <StyledFormInput
              label={label}
              value={isEmpty(localValue) ? '' : ' '}
              tabIndex={-1}
              focused={focused}
              hovered={hovered}
              error={error}
              dataTestId={dataTestId}
            />
          ) : null}
        </EditorField>
      </Slate>
    </Box>
  );
};

export default memo(BaseEditor);
